import React from 'react';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import Avatar from '../../ui/components/avatar';

import styles from './styles.module.scss';

const UserInfoPanel = (props) => {
  const {
    self,
    userData,
    fetchFollowers,
    fetchSubscribes,
    openAvatarUploader,
  } = props;

  return (
    <div className={styles.infoPanel}>
      <div className={styles.followers} onClick={fetchFollowers}>
        ПОДПИСЧИКИ
        <div className={styles.count}>{userData.followersCount}</div>
        {userData.pendingCount > 0 && (
          <div className={styles.new}>+{userData.pendingCount}</div>
        )}
      </div>
      <div className={styles.mainInfo}>
        <p className={styles.username}>{userData.username}</p>
        <div className={styles.avatarBlock}>
          <Avatar
            avatar={userData.avatar}
            placeholder={`#${userData.id}`}
            size={100}
          />
          {self && (
            <div
              onClick={openAvatarUploader}
              className={styles.changeAvatarBtn}
            >
              <BsFillPlusCircleFill size={25} />
              <div className={styles.plusBg} />
            </div>
          )}
        </div>
        <p className={styles.fio}>
          {userData.lastName} {userData.firstName} {userData.middleName}
        </p>
        <p className={styles.description}>{userData.description}</p>
      </div>
      <div className={styles.subscribes} onClick={fetchSubscribes}>
        ПОДПИСКИ
        <div className={styles.count}>{userData.subscribesCount}</div>
      </div>
    </div>
  );
};

export default UserInfoPanel;

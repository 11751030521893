import { createContext, useContext } from 'react';
import AuthStore from './auth';
import ProfileStore from './profile';
import EventsStore from './events';
import FiltersStore from './filters';

const authContext = createContext(AuthStore);
export const useAuthStore = () => {
  return useContext(authContext);
};

const profileContext = createContext(ProfileStore);
export const useProfileStore = () => {
  return useContext(profileContext);
};

const eventsContext = createContext(EventsStore);
export const useEventsStore = () => {
  return useContext(eventsContext);
};

const filtersContext = createContext(FiltersStore);
export const useFiltersStore = () => {
  return useContext(filtersContext);
};

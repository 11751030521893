import { createPortal } from 'react-dom';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles.module.scss';

export default function Loader() {
  const loaderPortal = document.querySelector('#loader');

  const loader = (
    <div className={styles.loaderOverlay}>
      <CircularProgress color="inherit" />
    </div>
  );

  if (loaderPortal) {
    return createPortal(loader, loaderPortal);
  } else return null;
}

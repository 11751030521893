export function toast(msg, type) {
  switch (type) {
    case 'success':
      window.PrimeToast.success(msg);
      break;
    case 'error':
      window.PrimeToast.error(msg);
      break;
    case 'warn':
      window.PrimeToast.warn(msg);
      break;
    case 'info':
      window.PrimeToast.info(msg);
      break;
    default:
      window.PrimeToast.info(msg);
  }
}

export function getUserLabel(user) {
  if (user == null) return '';

  let userLabel = user.username;

  if (user.username === null) {
    if (
      !(
        user.first_name == null &&
        user.last_name == null &&
        user.middle_name == null
      )
    )
      userLabel = `${user.last_name} ${user.first_name} ${user.middle_name}`;
    else userLabel = `#${user.user_id}`;
  }

  return userLabel;
}

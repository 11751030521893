import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useProfileStore } from '../../store/index';
import {
  getUserEvents,
  getUserFollowers,
  getUserProfile,
  getUserSubscribes,
  getUserSubscription,
  getUserSubscriptionExists,
  subscribeUser,
  unSubscribeUser,
} from '../../helpers/fetch';

import styles from './styles.module.scss';
import Avatar from '../../ui/components/avatar';
import { getUserLabel } from '../../helpers/utils';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import EventCard from '../../ui/components/eventCard';
import UserListModal from '../../ui/components/usersListModal';
import Loader from '../../ui/components/loader';
import UserInfoPanel from '../../components/userInfoPanel';

let followers = [];
let subscribes = [];

const User = observer(() => {
  const profileStore = useProfileStore();
  const [user, setUser] = useState({});
  const events = useRef(null);
  const { userId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubscribePending, setIsSubscribePending] = useState(false);

  const [usersModal, setUsersModal] = useState(null);

  useEffect(() => {
    setLoading(true);
    setUsersModal(null);
    setIsSubscribed(false);
    setIsSubscribePending(false);

    async function fetchUser() {
      if (userId == profileStore.id) {
        navigate(`/profile`);
        return;
      }

      const _user = await getUserProfile(userId, 'avatar.file');
      if (!_user) return;
      setUser(_user);

      events.current = await getUserEvents(userId, ['categories']);
      if (!events.current) return;
      events.current = events.current.list;

      const exists = await getUserSubscriptionExists(userId);
      if (exists) {
        const result = await getUserSubscription(userId);
        if (result) {
          if (result.accepted_at == null) setIsSubscribePending(true);
          else setIsSubscribed(true);
        }
      }

      setLoading(false);
    }

    fetchUser();
  }, [userId]);

  const fetchFollowers = async () => {
    setLoading(true);

    followers = [];
    let offset = 0;

    async function getFollowers() {
      const result = await getUserFollowers(
        userId,
        'profile.avatar.file',
        100 * offset
      );
      if (!result) {
        return;
      }

      followers.push(...result.list);
      if (followers.length < result.total_count) {
        offset++;
        await getFollowers();
      }
    }

    await getFollowers();
    setLoading(false);
    showFollowers();
  };

  const fetchSubscribes = async () => {
    setLoading(true);

    subscribes = [];
    let offset = 0;

    async function getSubscribes() {
      const result = await getUserSubscribes(
        userId,
        'user.profile.avatar.file',
        100 * offset
      );
      if (!result) {
        return;
      }

      subscribes.push(...result.list);
      if (subscribes.length < result.total_count) {
        offset++;
        await getSubscribes();
      }
    }

    await getSubscribes();
    setLoading(false);
    showSubscribes();
  };

  const onSubscribe = async () => {
    const success = await subscribeUser(userId);
    if (success) {
      setIsSubscribePending(true);
    }
  };

  const onUnSubscribe = async () => {
    const success = await unSubscribeUser(userId);
    if (success) {
      setIsSubscribePending(false);
      setIsSubscribed(false);
    }
  };

  const showFollowers = () => {
    setUsersModal({
      title: ['Подписчики пользователя'],
      users: [followers],
      subscribes: false,
      acceptHandler: null,
      cancelHandler: null,
    });
  };

  const showSubscribes = () => {
    setUsersModal({
      title: ['Подписки пользователя'],
      users: [subscribes.map((s) => s.user)],
      subscribes: true,
      acceptHandler: null,
      cancelHandler: null,
    });
  };

  const closeUsersModal = () => {
    setUsersModal(null);
  };

  const openUserProfile = (userId) => {
    navigate(`/user/${userId}`);
  };

  const openEvent = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const userData = useMemo(() => {
    if (Object.keys(user).length == 0)
      return {
        username: '',
        description: '',
        followersCount: 0,
        subscribesCount: 0,
        avatar: null,
        firstName: '',
        lastName: '',
        middleName: '',
      };

    return {
      username: user.username,
      description: user.description,
      followersCount: user.accepted_subscribers_count,
      subscribesCount: user.accepted_subscriptions_count,
      avatar: user.avatar?.file.url,
      firstName: user.first_name,
      lastName: user.last_name,
      middleName: user.middle_name,
    };
  }, [user]);

  return (
    <>
      {loading && <Loader />}
      {usersModal && (
        <UserListModal
          owner={false}
          {...usersModal}
          opened={usersModal !== null}
          openUserProfile={openUserProfile}
          onClose={closeUsersModal}
        />
      )}
      <div className={styles.user}>
        <UserInfoPanel
          self={false}
          userData={userData}
          fetchFollowers={fetchFollowers}
          fetchSubscribes={fetchSubscribes}
        />
        <div className={styles.subscribeSection}>
          <div className={styles.subsribe}>
            {isSubscribePending && (
              <div className={styles.pending}>
                Ваша заявка
                <br />
                на рассмотрении
              </div>
            )}
            {isSubscribed || isSubscribePending ? (
              <SimpleButton text="Отписаться" onClick={onUnSubscribe} />
            ) : (
              <SimpleButton text="Подписаться" onClick={onSubscribe} />
            )}
          </div>
        </div>
        <div className={styles.eventsList}>
          {events.current?.map((event) => (
            <EventCard key={event.id} event={event} onClick={openEvent} />
          ))}
        </div>
      </div>
    </>
  );
});

export default User;

import React from 'react';
import Button from '@mui/material/Button';
import styles from './SimpleButton.module.scss';

const SimpleButton = (props) => {
  const { onClick, text, variant, disabled, classes, startIcon, endIcon } =
    props;

  return (
    <Button
      className={`${classes || ''} ${styles.simpleButton}`}
      onClick={onClick}
      variant={variant ? variant : 'contained'}
      disabled={disabled || false}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {text}
    </Button>
  );
};

export default SimpleButton;

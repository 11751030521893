import axios from 'axios';
import AuthStore from '../store/auth';
import { toast } from '../helpers/utils';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

// USERS
export const getUser = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/${userId}/one/get`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getEventParticipants = async (eventId, relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/event/${eventId}/participants/many/get`,
      { relationships: [relationships] },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getMyParticipants = async (relationships, offset) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/my/subscribers/many/accepted/get`,
      { relationships: [relationships], offset },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getMyPendings = async (relationships, offset) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/my/subscribers/many/pending/get`,
      { relationships: [relationships], offset },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const acceptFollower = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/my/subscriber/${userId}/accept`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const cancelFollower = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/my/subscriber/${userId}/cancel`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getUserFollowers = async (userId, relationships, offset) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/${userId}/subscribers/many/accepted/get`,
      { relationships: [relationships], offset },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// PROFILES

export const getUserProfile = async (userId, relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/profiles/user/${userId}/one/get`,
      { relationships: [relationships] },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// EVENTS
export const checkMySubscriptionOnEvent = async (event_id) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/event-participants/my/event/${event_id}/exists`,
      { event_id },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getEventsByArea = async (area, offset, filters, relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/many/area/get`,
      {
        ...area,
        filter_paid: filters.paid,
        filter_category_type_ids: filters.category,
        relationships: relationships,
        offset,
      },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getEvents = async (limit, offset, filters, relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/many/get`,
      {
        limit,
        offset,
        filter_format: filters.format,
        filter_paid: filters.paid,
        filter_category_type_ids: filters.category,
        relationships,
      },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getEventsByCity = async (
  city_id,
  limit,
  offset,
  filters,
  relationships
) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/city/${city_id}/many/get`,
      {
        limit,
        offset,
        filter_format: filters.format,
        filter_paid: filters.paid,
        relationships,
      },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const saveEventCover = async (eventId, cover) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/${eventId}/cover/save`,
      { cover: cover },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const participate = async (eventId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/${eventId}/participate`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const unparticipate = async (eventId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/${eventId}/unparticipate`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getUserEvents = async (userId, relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/user/${userId}/many/ownership/get`,
      { relationships },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

//USER SUBSCRIPTIONS

export const getUserSubscriptionExists = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/user/${userId}/exists`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getUserSubscription = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/user/${userId}/one/get`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      if (response.status === 204) return false;
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getUserSubscribes = async (userId, relationships, offset) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/user/${userId}/many/accepted/get`,
      { relationships: [relationships], offset },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getMySubscribes = async (relationships, offset) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/many/get`,
      { relationships: [relationships], offset },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const subscribeUser = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/user/${userId}/create`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const unSubscribeUser = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/user/${userId}/delete`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// VERIFICATION

export const sendEmailVerification = async () => {
  return axios
    .postForm(
      `${API_ENDPOINT}/verification/email/notify`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      toast('Письмо успешно отправлено на ваш email', 'success');
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const varificateEmail = async (url) => {
  return axios
    .post(url)
    .then((response) => {
      toast('Ваш email успешно подтвержден!', 'success');
      AuthStore.setVerified(true);
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// DICTIONARIES
let cacheCities = {
  total_count: 0,
  list: [],
};
export const setCacheCities = (list) => {
  cacheCities = {
    total_count: list.length,
    list: list,
  };
};

export const getAllCities = async () => {
  if (cacheCities.total_count > 0) return cacheCities.list;

  const cities = [];
  const limit = 500;
  let offset = 0;

  async function _getCities() {
    const result = await getCities(limit, limit * offset);
    if (!result) {
      return;
    }

    cities.push(...result.list);
    if (cities.length < result.total_count) {
      offset++;
      await _getCities();
    } else {
      return;
    }
  }

  await _getCities();
  setCacheCities(cities);
  return cities;
};

const getCities = async (limit, offset) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/dicts/city/many/get`,
      { limit, offset },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

let cacheCategories = {
  total_count: 0,
  list: [],
};

export const getCategories = async () => {
  if (cacheCities.total_count > 0) return cacheCategories;

  return axios
    .postForm(
      `${API_ENDPOINT}/dicts/event-category-type/many/get`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      cacheCategories = response.data;
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// ERROR HANDLER
const errorHandler = (data) => {
  if (data.response?.data) toast(data.response.data.message, 'error');
  else toast('Неизвестная ошибка', 'error');
};

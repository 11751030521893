import { makeAutoObservable } from 'mobx';

class FilterStore {
  filters = {};

  constructor() {
    makeAutoObservable(this);
    this.checkStored();
  }

  checkStored() {
    const _filters = localStorage.getItem('filters');
    if (_filters) {
      this.filters = JSON.parse(_filters);
    }
  }

  saveFilters(data) {
    localStorage.setItem('filters', JSON.stringify(data));
  }
}

const store = new FilterStore();

export default store;

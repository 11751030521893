import React from 'react';
import SimpleButton from '../SimpleButton/SimpleButton';

const IconButton = (props) => {
  const { onClick, text, variant, disabled, classes, icon, iconPos } = props;

  return (
    <SimpleButton
      classes={classes}
      onClick={onClick}
      text={text}
      variant={variant}
      disabled={disabled}
      startIcon={iconPos == 'start' && icon}
      endIcon={iconPos == 'end' && icon}
    />
  );
};

export default IconButton;

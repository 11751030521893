import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from './store/index';
import { Routes, Route, Navigate } from 'react-router-dom';
import styles from './App.module.scss';

import Loader from './ui/components/loader';

import Welcome from './pages/welcome';
import Auth from './pages/auth';
import Profile from './pages/profile';
import CreateEvent from './pages/createevent';
import Events from './pages/events';
import Event from './pages/event';
import User from './pages/user';
import Verification from './pages/verification';
import About from './pages/about';
import Settings from './pages/settings';
import ErrorPage from './pages/error/error-page';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Layout from './components/layout';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { cyan } from '@mui/material/colors';

import WebFont from 'webfontloader';

import 'moment/locale/ru';

const primaryColors = {
  50: '#e1f5fe',
  100: '#b3e5fc',
  200: '#81d4fa',
  300: '#4fc3f7',
  400: '#29b6f6',
  500: '#ff7d31',
  600: '#039be5',
  700: '#ff650b',
  800: '#0277bd',
  900: '#01579b',
  A100: '#80d8ff',
  A200: '#40c4ff',
  A400: '#00b0ff',
  A700: '#0091ea',
};

const theme = createTheme({
  palette: {
    primary: primaryColors,
    secondary: cyan,
  },
  typography: {
    fontFamily: 'RobotoB',
    lineHeight: 'normal',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'RobotoR',
      },
    },
  },
});

//MOCK DATA
// if (window.location.origin == 'http://localhost') {
//   if (!window.Telegram) {
//     window.Telegram.WebView.initParams.tgWebAppData: '',
//   }
// }

const App = observer((props) => {
  const authStore = useAuthStore();
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.PrimeToast = toast || {};

    // async function loginFromTelegram() {
    //   setLoading(true);
    //   const result = await authStore.telegramLogin(
    //     window.Telegram.WebView.initParams.tgWebAppData
    //   );
    //   setLoading(false);
    // }

    if (window.Telegram?.WebView.initParams) {
      authStore.isTgWebApp = true;
      window.Telegram.WebApp.backgroundColor = '#ffffff';
      if (authStore.isTelegramAuth) {
        // loginFromTelegram();
        authStore.checkAccessTokenFromTelegramCloud();
      }
    }

    const webFontConfig = {
      custom: {
        families: ['RobotoR', 'RobotoB'],
      },
      active: () => setFontsLoaded(true),
      timeout: 30000,
    };

    WebFont.load(webFontConfig);
  }, []);

  useEffect(() => {
    async function fetchMe() {
      setLoading(true);
      const response = await authStore.getMe();
      if (response) {
        authStore.setVerified(response.email_verified_at != null);
      }
      setLoading(false);
    }
    if (authStore.accessToken) fetchMe();
  }, [authStore.accessToken]);

  let routes = null;

  if (
    !authStore.accessToken ||
    (!authStore.verified && !authStore.isTelegramAuth)
  ) {
    routes = (
      <Routes>
        {!authStore.verified && authStore.accessToken ? (
          <>
            <Route path="/verification" element={<Verification />} />
            <Route
              path="*"
              element={<Navigate to="/verification" replace={true} />}
            />
          </>
        ) : (
          <>
            <Route index element={<Welcome />} />
            {/* <Route path="/forgot" element={<Auth />} /> */}
            <Route path="/login" element={<Auth />} />
            <Route path="/register" element={<Auth />} />
            <Route path="/verification" element={<Verification />} />
            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </>
        )}
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route
          index
          element={
            <Layout active="profile">
              <Profile />
            </Layout>
          }
        />
        <Route
          path="/create"
          element={
            <Layout active="create">
              <CreateEvent />
            </Layout>
          }
        />
        <Route
          path="/events"
          element={
            <Layout active="events">
              <Events />
            </Layout>
          }
        />
        <Route
          path="/event/:eventId"
          element={
            <Layout active="event">
              <Event />
            </Layout>
          }
        />
        <Route
          path="/user/:userId"
          element={
            <Layout active="user">
              <User />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout active="about">
              <About />
            </Layout>
          }
        />
        <Route
          path="/settings"
          element={
            <Layout active="settings">
              <Settings />
            </Layout>
          }
        />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    );
  }

  if (loading || !fontsLoaded)
    return (
      <div className={styles.AppWrapper}>
        <Loader />
        <div id="loader" />
      </div>
    );

  return (
    <div className={styles.AppWrapper}>
      <ThemeProvider theme={theme}>
        <div className={styles.App}>
          <ToastContainer />
          {routes}
        </div>
        <div id="loader" />
      </ThemeProvider>
    </div>
  );
});

export default App;

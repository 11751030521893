import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import styles from './styles.module.scss';
import { Link } from '@mui/material/';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from '../../store/index';
import Loader from '../../ui/components/loader';

const Welcome = observer(() => {
  const navigate = useNavigate();
  const [animationStart, setAnimationStart] = useState(false);
  const [animationEnded, setAnimationEnded] = useState(false);
  const [loading, setLoading] = useState(false);

  const authStore = useAuthStore();

  const screenWidth = window.innerWidth;
  let fromZoom = 3;

  if (screenWidth <= 1280) {
    fromZoom = 2.6;

    if (screenWidth < 800) {
      fromZoom = 2.2;

      if (screenWidth < 500) {
        fromZoom = 1.3;
      }

      if (screenWidth < 400) {
        fromZoom = 1.2;
      }
    }
  }

  const spring = useSpring({
    delay: 1000,
    from: { zoom: fromZoom },
    to: { zoom: 1 },
    onStart: (e) => {
      onTitleAnimationStart();
    },
    onRest: (e) => {
      if (e.finished === true) onTitleAnimationFinish();
    },
    config: { friction: 50 },
  });

  const onTitleAnimationStart = () => {
    setAnimationStart(true);
  };

  const onTitleAnimationFinish = () => {
    setAnimationEnded(true);
  };

  const autorizeFromTelegram = async () => {
    setLoading(true);
    const result = await authStore.telegramLogin(
      window.Telegram?.WebView.initParams.tgWebAppData
    );
    setLoading(false);
  };

  return (
    <div className={styles.welcome}>
      {loading && <Loader />}
      <div className={styles.animatedBlock}>
        <animated.div
          className={styles.logo}
          style={{ ...spring }}
        ></animated.div>
        <p
          className={styles.description}
          style={{ opacity: animationStart ? 0 : 1 }}
        >
          лучший ивент сервис рунета
        </p>
      </div>

      <div
        className={styles.authBlock}
        style={{ opacity: animationEnded ? 1 : 0 }}
      >
        {authStore.isTgWebApp && (
          <SimpleButton
            onClick={autorizeFromTelegram}
            text="Авторизоваться через Telegram"
          />
        )}

        <SimpleButton
          onClick={() => navigate('/login')}
          text={authStore.isTgWebApp ? 'Войти по email' : 'Войти'}
        />
        <Link href="/register">регистрация</Link>
      </div>
    </div>
  );
});

export default Welcome;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BsCoin, BsFillLockFill, BsFillUnlockFill } from 'react-icons/bs';

import { YMaps, Map, Placemark, Clusterer } from '@pbe/react-yandex-maps';
import customMarkerDefault from '../../assets/imgs/map_marker_default.png';
import customMarkerNow from '../../assets/imgs/map_marker_now.png';

import moment from 'moment';

import styles from './styles.module.scss';
import { toast } from '../../helpers/utils';
import { getEventsByArea } from '../../helpers/fetch';
import { categoryIcons } from '../../helpers/caterogy';

const YANDEX_API_KEY = process.env.REACT_APP_YAPI;

const EventCard = (props) => {
  const { event, selected, onClick } = props;
  if (event.isEmpty) {
    return (
      <div key={event.id} className={`${styles.eventCard} ${styles.empty}`}>
        <span className={styles.name} />
        <div className={styles.dollar} />
        <div className={styles.eventTime} />
      </div>
    );
  }

  const startTime = moment(event.start_at).format('DD.MM HH:mm');
  const endTime = moment(event.end_at).format('DD.MM HH:mm');

  return (
    <div
      key={event.id}
      className={`${styles.eventCard} ${
        selected == event.id ? styles.selected : null
      } ${event.inTime ? styles.inTime : null}`}
      onClick={() => onClick(event)}
    >
      <div className={styles.header}>
        {event.privacy_type === 'PU' ? (
          <BsFillUnlockFill className={styles.privacy} />
        ) : (
          <BsFillLockFill className={styles.privacy} />
        )}
        {event.cost > 0 ? (
          <div className={styles.dollar}>
            <BsCoin />
            <BsCoin />
            <BsCoin />
          </div>
        ) : (
          <div className={styles.free}>БЕСПЛАТНО</div>
        )}
        <div className={styles.eventTime}>
          {startTime} - {endTime}
        </div>
        <div className={styles.categories}>
          {event.categories.map((cat) => (
            <span key={cat.id}>{categoryIcons[cat.type_id - 1]}</span>
          ))}
        </div>
      </div>
      <div className={styles.content}>{event.name}</div>
    </div>
  );
};

let eventsOffset = 0;

const MapEvents = (props) => {
  const { onEventOpen, filters } = props;
  const [events, setEvents] = useState([]);
  const [totalEvents, setTotalEvents] = useState(0);
  const [myLocation, setMyLocation] = useState({
    latitude: 55.75406062131298,
    longitude: 37.62047914100069,
  });
  const [searchBounds, setSearchBounds] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(-1);

  const mapRef = useRef(null);

  const getCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        successLocationHandler,
        errorLocationHandler
      );
    } else {
      alert('Местоположение недоступно');
    }
  };

  const successLocationHandler = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    // setMyLocation({
    //   latitude,
    //   longitude,
    // });
    mapRef.current.setCenter([latitude, longitude], 17);
  };

  const errorLocationHandler = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        toast(
          'Невозможно узнать ваше местоположение, необходимо дать доступ.',
          'warn'
        );
        break;
      case error.POSITION_UNAVAILABLE:
        toast('Невозможно узнать ваше местоположение.', 'warn');
        break;
      case error.TIMEOUT:
        toast('Вышло время ответа о получении вашего местоположения.', 'warn');
        break;
      case error.UNKNOWN_ERROR:
        toast('Неизвестная ошибка о получении вашего местоположения.', 'error');
        break;
    }
  };

  const onMapLoad = () => {
    getCurrentLocation();
    calculateBounds();
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.events.add('actionend', onMapActionEnd);

      window.onEventOpen = onEventOpen;
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.events.remove('actionend', onMapActionEnd);
      }
    };
  }, [mapRef.current]);

  useEffect(() => {
    async function fetchEvents() {
      const area = {
        tl_coordinate_latitude: searchBounds[1][0].clamp(-90, 90), // Координата широты верхней левой точки
        tl_coordinate_longitude: searchBounds[0][1].clamp(-180, 180), // Координата долготы верхней левой точки
        br_coordinate_latitude: searchBounds[0][1].clamp(-90, 90), // Координата широты нижней правой точки
        br_coordinate_longitude: searchBounds[1][1].clamp(-180, 180), // Координата долготы нижней правой точки
      };
      async function getEvents(area, newBounds) {
        const result = await getEventsByArea(area, eventsOffset, filters, [
          'categories',
          'location',
        ]);
        if (result) {
          if (newBounds) {
            setTotalEvents(result.total_count);
          }
          const newEvents = newBounds
            ? result.list
            : [...events, ...result.list];
          setEvents(newEvents);

          if (result.total_count > 100 * (eventsOffset + 1)) {
            eventsOffset++;
            await getEvents(area, false);
          }
        }
      }
      eventsOffset = 0;
      await getEvents(area, true);
    }

    if (searchBounds) fetchEvents();
  }, [searchBounds, filters]);

  const onMarkerClick = (eventId) => {
    setSelectedEvent(eventId);
  };

  const onEventFirstClick = (event) => {
    if (selectedEvent == event.id) {
      mapRef.current.destroy();
      onEventOpen(event.id);
    } else {
      setSelectedEvent(event.id);

      mapRef.current
        .panTo([
          event.location.coordinate_latitude || myLocation.latitude,
          event.location.coordinate_longitude || myLocation.longitude,
        ])
        .then(() => mapRef.current.setZoom(17, { duration: 1000 }));
    }
  };

  const onMapActionEnd = (event) => {
    calculateBounds();
  };

  const calculateBounds = () => {
    const bounds = mapRef.current.getBounds();
    setSearchBounds(bounds);
  };

  const preparedEvents = useMemo(
    () =>
      events.map((event) => {
        const startTime = moment(event.start_at).valueOf();
        const endTime = moment(event.end_at).valueOf();
        const nowTime = moment().valueOf();

        let inTime = false;
        if (startTime < nowTime && nowTime < endTime) {
          inTime = true;
        }

        return { ...event, inTime };
      }),
    [events]
  );

  const eventItems = useMemo(() => {
    const items = [];
    for (let i = 0; i < totalEvents; i++) {
      if (i < preparedEvents.length) {
        items.push(
          <EventCard
            key={preparedEvents[i].id}
            event={preparedEvents[i]}
            selected={selectedEvent}
            onClick={onEventFirstClick}
          />
        );
      } else {
        items.push(
          <EventCard
            key={`empty_event-${i}`}
            event={{
              isEmpty: true,
              id: `empty_event-${0}`,
            }}
          />
        );
      }
    }

    return items;
  }, [preparedEvents, totalEvents, selectedEvent]);

  return (
    <div className={styles.events}>
      <div className={styles.eventsContainer}>
        <div className={styles.eventsList}>{eventItems}</div>
      </div>
      <div className={styles.mapContainer}>
        <YMaps query={{ apikey: YANDEX_API_KEY }}>
          <Map
            width="100%"
            height="100%"
            defaultState={{
              center: [55.75406062131298, 37.62047914100069],
              zoom: 5,
              controls: ['zoomControl'],
            }}
            modules={[
              'control.ZoomControl',
              'geoObject.addon.balloon',
              'geoObject.addon.hint',
            ]}
            instanceRef={(ref) => (mapRef.current = ref)}
            onLoad={onMapLoad}
          >
            <Clusterer
              options={{
                preset: 'islands#invertedOrangeClusterIcons',
                groupByCoordinates: false,
                gridSize: 256,
              }}
            >
              {preparedEvents.map((event) => (
                <Placemark
                  key={event.id}
                  geometry={[
                    event.location.coordinate_latitude,
                    event.location.coordinate_longitude,
                  ]}
                  options={{
                    iconLayout: 'default#image',
                    iconImageHref: event.inTime
                      ? customMarkerNow
                      : customMarkerDefault,
                    iconImageSize: [32, 32],
                    hideIconOnBalloonOpen: false,
                  }}
                  properties={{
                    balloonContentHeader: event.name,
                    balloonContentBody: moment(event.start_at).format(
                      'дата DD.MM, время HH:mm'
                    ),
                    balloonContentFooter: `<input class="${styles.popupMarkerOpenBtn}" type="button" onclick={window.onEventOpen(${event.id})} value="ОТКРЫТЬ"/>`,
                    hintContent: event.name,
                  }}
                  onClick={() => onMarkerClick(event.id)}
                />
              ))}
            </Clusterer>
          </Map>
        </YMaps>
      </div>
    </div>
  );
};

export default MapEvents;

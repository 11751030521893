import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useEventsStore, useProfileStore } from '../../store/index';
import { useParams, useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import moment from 'moment';
import {
  participate,
  unparticipate,
  getEventParticipants,
  checkMySubscriptionOnEvent,
} from '../../helpers/fetch';
import Avatar from '../../ui/components/avatar';
import { getUserLabel } from '../../helpers/utils';
import { BsFilePersonFill, BsPinMapFill } from 'react-icons/bs';
import UserListModal from '../../ui/components/usersListModal';
import Loader from '../../ui/components/loader';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

import customMapMarker from '../../assets/imgs/map_marker_default.png';
import Share from '../../ui/components/share';

const YANDEX_API_KEY = process.env.REACT_APP_YAPI;

let participants = [];

const Event = observer(() => {
  const event = useRef({
    name: '',
    description: '',
    format: 'real',
    avatar: null,
    participant_min_age: null,
    participant_max_age: null,
    participant_min_count: null,
    participant_max_count: null,
    cost: 0,
  });
  const myEvent = useRef(false);

  const [usersModal, setUsersModal] = useState(null);
  const mapRef = useRef(null);

  const [loading, setLoading] = useState(true);

  const [isParcipiant, setIsParcipiant] = useState(false);
  const [totalParcipicants, setTotalParcipicants] = useState(0);

  const eventsStore = useEventsStore();
  const profileStore = useProfileStore();
  const { eventId } = useParams();
  const navigate = useNavigate();

  const isVirtual = event.current.format == 'virtual';

  const ageControlSuccess = useMemo(() => {
    if (event.current) {
      if (
        event.current.participant_min_age == null ||
        event.current.participant_max_age == null
      )
        return true;
      return (
        event.current.participant_min_age <= profileStore.age &&
        profileStore.age <= event.current.participant_max_age
      );
    }
    return false;
  }, [event.current]);

  const countControlSuccess = useMemo(() => {
    if (!event.current) return false;
    if (
      event.current.participant_min_count == null ||
      event.current.participant_max_count == null
    )
      return true;
    return totalParcipicants < event.current.participant_max_count;
  }, [totalParcipicants, event.current]);

  const [timeControlSuccess, timeLabel, timeStyle, startTime, endTime] =
    useMemo(() => {
      if (!event.current) return [false, '', '', 0, 0];

      const startTime = moment(event.current.start_at);
      const endTime = moment(event.current.end_at);
      const nowTime = moment().valueOf();

      let timeLabel = '';
      let timeStyle = '';
      let timeControlSuccess = true;

      if (startTime.valueOf() < nowTime && nowTime < endTime.valueOf()) {
        timeLabel = 'сейчас идёт';
        timeStyle = styles.inTime;
      } else if (nowTime > endTime) {
        timeLabel = 'завершено';
        timeStyle = styles.outOfTime;
        timeControlSuccess = false;
      }

      return [timeControlSuccess, timeLabel, timeStyle, startTime, endTime];
    }, [event.current]);

  const canParcipiate = useMemo(
    () => ageControlSuccess && countControlSuccess && timeControlSuccess,
    [ageControlSuccess, countControlSuccess, timeControlSuccess]
  );

  useEffect(() => {
    setLoading(true);

    async function fetchEvent() {
      event.current = await eventsStore.getEvent(parseInt(eventId), [
        'categories.type',
        'location.city',
        'cover.file',
        'author.profile.avatar.file',
      ]);
      if (!event.current) return;

      if (profileStore.id === event.current.author_id) myEvent.current = true;

      setTotalParcipicants(event.current.participants_count);

      const result = await checkMySubscriptionOnEvent(eventId);
      setIsParcipiant(result);

      setLoading(false);
    }

    fetchEvent();
  }, []);

  const fetchEventParticipants = async () => {
    setLoading(true);

    participants = [];
    let offset = 0;

    async function _getEventParticipants() {
      const result = await getEventParticipants(
        event.current.id,
        'profile.avatar.file',
        100 * offset
      );
      if (!result) {
        return;
      }

      participants.push(...result.list);
      if (participants.length < result.total_count) {
        offset++;
        await _getEventParticipants();
      }
    }

    await _getEventParticipants();
    setLoading(false);
    showEventParticipants();
  };

  const showEventParticipants = () => {
    setUsersModal({
      title: ['Участники мероприятия'],
      users: [participants],
      subscribes: false,
      acceptHandler: null,
      cancelHandler: null,
    });
  };

  const onParticipate = async () => {
    const response = await participate(event.current.id);
    if (response) {
      setIsParcipiant(true);
      setTotalParcipicants((prev) => ++prev);
    }
  };

  const onUnparticipate = async () => {
    const result = await unparticipate(event.current.id);
    if (result) {
      setIsParcipiant(false);
      setTotalParcipicants((prev) => --prev);
    }
  };

  const closeUsersModal = () => {
    setUsersModal(null);
  };

  const openUserProfile = (userId) => {
    if (userId === profileStore.id) navigate('/profile');
    else navigate(`/user/${userId}`);
  };

  const onCenterPoint = () => {
    mapRef.current.setCenter(
      [
        event.current.location?.coordinate_latitude,
        event.current.location?.coordinate_longitude,
      ],
      17
    );
  };

  let parcipiantCount = 'неограничено';
  if (
    event.current.participant_min_count !== null &&
    event.current.participant_max_count !== null
  ) {
    parcipiantCount = `от ${event.current?.participant_min_count} до ${event.current?.participant_max_count}`;
  }

  let parcipiantAge = 'любой возраст';
  if (
    event.current.participant_min_age !== null &&
    event.current.participant_max_age !== null
  ) {
    parcipiantAge = `от ${event.current?.participant_min_age} до ${event.current?.participant_max_age}`;
  }

  let subscribeDescription = 'Вы не участвуете в мероприятии';

  if (isParcipiant) subscribeDescription = 'Вы участвуете в мероприятии';
  else {
    if (!timeControlSuccess)
      subscribeDescription = 'Время проведения мероприятия окончено';
    else if (!ageControlSuccess)
      subscribeDescription = 'Вы не проходите по возрасту мероприятия';
    else if (!countControlSuccess)
      subscribeDescription =
        'Мероприятие набрало максимум возможных участников';
  }

  return (
    <>
      {loading && <Loader />}
      {usersModal && (
        <UserListModal
          owner={false}
          {...usersModal}
          opened={usersModal !== null}
          openUserProfile={openUserProfile}
          onClose={closeUsersModal}
        />
      )}
      <div className={styles.event}>
        <div className={styles.information}>
          <Share
            shareUrl={window.location.href}
            shareText={event.current.name}
          />
          <div className={styles.name}>{event.current.name}</div>
          <div className={styles.creator}>
            {myEvent.current ? (
              <div
                className={styles.user}
                onClick={() =>
                  openUserProfile(event.current.author?.profile.user_id)
                }
              >
                <span className={styles.userLabel}>
                  Вы являетесь организатором данного мероприятия
                </span>
              </div>
            ) : (
              <>
                <span>Организатор:</span>
                <div className={styles.user}>
                  <div className={styles.avatar}>
                    <Avatar
                      avatar={event.current.author?.profile.avatar?.file.url}
                      size={24}
                    />
                  </div>
                  <span
                    className={styles.userLabel}
                    onClick={() =>
                      openUserProfile(event.current.author?.profile.user_id)
                    }
                  >
                    {getUserLabel(event.current.author?.profile)}
                  </span>
                </div>
              </>
            )}
          </div>
          {event.current.cover?.file?.url && (
            <div className={styles.cover}>
              <img src={event.current.cover.file.url} />
            </div>
          )}
          <div className={styles.description}>
            Описание: {event.current.description}
          </div>
          <div
            className={`${styles.ages} ${
              ageControlSuccess ? '' : styles.error
            }`}
          >
            <span>Возрастные ограничения: {parcipiantAge}</span>
            {!ageControlSuccess && (
              <span> (ваш возраст {profileStore.age})</span>
            )}
          </div>
          <div className={styles.category}>
            {event.current.categories?.length == 1
              ? 'Категория: '
              : 'Категории: '}
            {event.current.categories?.map((cat) => (
              <span key={cat.id} title={cat.type.description}>
                {' '}
                {cat.type.name}
              </span>
            ))}
          </div>
          <div className={styles.count}>
            <span>Количество участников: {parcipiantCount}</span>{' '}
            <span
              className={styles.participants}
              onClick={myEvent.current ? fetchEventParticipants : null}
              title={
                myEvent.current ? 'Показать участников' : `Всего участников`
              }
            >
              <span className={styles.pcount}>{totalParcipicants}</span>
              <BsFilePersonFill />
              {totalParcipicants == event.current.participant_max_count && (
                <span>(максимум)</span>
              )}
            </span>
          </div>
          <div className={styles.cost}>
            Стоимость участия:{' '}
            {event.current.cost === 0 ? (
              <span className={styles.green}>бесплатно</span>
            ) : (
              `${event.current.cost} руб.`
            )}
          </div>
          <div className={`${styles.timeBlock} ${timeStyle}`}>
            <div>
              Время проведения:
              <br />
              {timeLabel}
            </div>
            <div className={styles.time}>
              <div>{startTime.format('llll')}</div>
              <div>{endTime.format('llll')}</div>
            </div>
          </div>
          {isVirtual && (
            <div className={styles.virtualAddress}>
              <span>Виртуальный адрес: </span>
              <span>{event.current.location?.address}</span>
            </div>
          )}
          {!isVirtual && (
            <div className={styles.address}>
              <div className={styles.title}>
                <span>Адрес: </span>
                <span>
                  {event.current.location?.city.type_short_name}{' '}
                  {event.current.location?.city.name}
                  {'. '}
                </span>
                <span>{event.current.location?.address}</span>
              </div>
              <div className={styles.map}>
                <YMaps query={{ apikey: YANDEX_API_KEY }}>
                  <Map
                    width="100%"
                    height="100%"
                    state={{
                      center: [
                        event.current.location?.coordinate_latitude,
                        event.current.location?.coordinate_longitude,
                      ],
                      zoom: 17,
                      controls: ['zoomControl'],
                    }}
                    modules={['control.ZoomControl']}
                    instanceRef={(ref) => (mapRef.current = ref)}
                  >
                    <Placemark
                      geometry={[
                        event.current.location?.coordinate_latitude,
                        event.current.location?.coordinate_longitude,
                      ]}
                      options={{
                        iconLayout: 'default#image',
                        iconImageHref: customMapMarker,
                        iconImageSize: [32, 32],
                      }}
                    />
                  </Map>
                </YMaps>
                <div
                  className={styles.navigateLink}
                  title="Вернуться к точке"
                  onClick={onCenterPoint}
                >
                  <BsPinMapFill />
                </div>
              </div>
            </div>
          )}
          {!myEvent.current && (
            <div className={styles.privacy}>
              {event.current.privacy_type === 'PU'
                ? 'Это публичное мероприятие, участвовать может любой желающий.'
                : 'Это приватное мероприятие, подайте заявку и организатор выберет участников.'}
            </div>
          )}
        </div>
        {isParcipiant ? (
          <div className={styles.subscribeSection}>
            <span>{subscribeDescription}</span>
            <SimpleButton
              classes={styles.subscribeBtn}
              text="ОТКАЗАТЬСЯ"
              onClick={onUnparticipate}
            />
          </div>
        ) : (
          <div className={styles.subscribeSection}>
            <span>{subscribeDescription}</span>
            <SimpleButton
              classes={styles.subscribeBtn}
              disabled={!canParcipiate}
              text="УЧАСТВОВАТЬ"
              onClick={onParticipate}
            />
          </div>
        )}
      </div>
    </>
  );
});

export default Event;

import React from 'react';

import styles from './styles.module.scss';

export default function About() {
  return (
    <div className={styles.about}>
      <div className={styles.card}>
        <h1 className={styles.title}>Мероприятник</h1>
        <div className={styles.description}>
          <p>Лучший ивент сервис рунета!</p>
          <p>
            Создавай мероприятия на любую тематику и приглашай людей участвовать
            в них!
          </p>
          <p>
            Будь то день рождение или официальная встреча, с помощью нашего
            сервиса вы всегда легко и просто сможете огранизовать любое
            мероприятие.
          </p>
        </div>
      </div>
      <div className={styles.card}>
        <h1 className={styles.title}>Контакты</h1>
        <div className={styles.description}>
          <p>По вопросам и предложениям:</p>
          <p>
            <strong className={styles.linkName}>email: </strong>
            <a href="mailto:meropriatnik@mail.ru">meropriatnik@mail.ru</a>
          </p>
          <p>
            <strong className={styles.linkName}>telegram: </strong>
            <a href="https://t.me/meropriatnik" target="_blank">
              t.me/meropriatnik
            </a>
          </p>
          <p>
            <strong className={styles.linkName}>vk: </strong>
            <a href="https://vk.com/meropriatnik" target="_blank">
              vk.com/meropriatnik
            </a>
          </p>
        </div>
      </div>
      <div className={styles.card}>
        <h1 className={styles.title}>Лицензионное соглашение</h1>
        <div className={styles.description}>
          <p>
            <a href="https://meropriatnik.ru/agreement.docx" target="_blank">
              файл с лицензионным соглашением
            </a>
          </p>
        </div>
      </div>
      <div className={styles.card}>
        <h1 className={styles.title}>Список грядущих обновлений (roadmap)</h1>
        <div className={styles.description}>
          <ul>
            <li>
              Типы мероприятий, такие как "спорт", "бизнес", "развлечения" и
              т.д.
            </li>
            <li>Фильтры для поиска мероприятий</li>
            <li>Сортировка мероприятий</li>
            <li>Добавление в избранное</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { setCookie, getCookie, deleteCookie } from '../helpers/cookie';
import { toast } from '../helpers/utils';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

class AuthenticationStore {
  accessToken = false;
  verified = false;
  isTgWebApp = false;
  isTelegramAuth = false;

  constructor() {
    makeAutoObservable(this);

    this.checkTelegramAuth();
    this.checkAccessToken();
  }

  checkAccessTokenFromTelegramCloud() {
    window.Telegram.WebApp.CloudStorage.getItem(
      'accessToken',
      (error, value) => {
        if (error == null) {
          this.accessToken = value || false;
        } else {
          this.logoutSuccess();
        }
      }
    );
  }

  checkAccessToken() {
    const token = getCookie('accessToken');
    if (token !== undefined) {
      this.accessToken = token;
    }
  }

  checkTelegramAuth() {
    const tgAuth = localStorage.getItem('telegramAuth');
    if (tgAuth !== null) {
      this.isTelegramAuth = tgAuth == 'true';
    }
  }

  setVerified(value) {
    this.verified = value;
  }

  async getMe() {
    return axios
      .postForm(
        `${API_ENDPOINT}/auth/me`,
        {},
        {
          headers: { Authorization: `Bearer ${this.accessToken}` },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  async login(email, password) {
    return axios
      .postForm(`${API_ENDPOINT}/auth/login`, { email, password })
      .then((response) => {
        this.loginSuccess(response.data);
        toast('Добро пожаловать', 'success');
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  // Telegram
  async telegramLogin(initData) {
    return axios
      .postForm(`${API_ENDPOINT}/telegram/login`, {
        data_check_string: initData,
      })
      .then((response) => {
        this.telegramAuthSuccess(response.data);
        toast('Успешно авторизовались через telegram', 'success');
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  telegramAuthSuccess(data) {
    this.isTelegramAuth = true;
    this.accessToken = data.access_token;

    window.Telegram.WebApp.CloudStorage.setItem(
      'accessToken',
      data.access_token
    );
    localStorage.setItem('telegramAuth', 'true');
  }

  loginSuccess(data) {
    this.accessToken = data.access_token;

    setCookie('accessToken', data.access_token, {
      secure: true,
      expires: new Date(data.access_token_expired_at).toGMTString(),
    });
  }

  async logout() {
    return axios
      .postForm(
        `${API_ENDPOINT}/auth/logout`,
        {},
        {
          headers: { Authorization: `Bearer ${this.accessToken}` },
        }
      )
      .then((response) => {
        this.logoutSuccess();
        toast('Успешно вышли, ждём вас снова', 'success');
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  logoutSuccess() {
    if (this.isTgWebApp)
      window.Telegram.WebApp.CloudStorage.removeItem('accessToken');
    else deleteCookie('accessToken');

    localStorage.removeItem('telegramAuth');
    localStorage.removeItem('profile');
    this.accessToken = false;
    this.verified = false;
    this.isTelegramAuth = false;
  }

  async register(email, password, password_confirmation) {
    return await axios
      .postForm(`${API_ENDPOINT}/auth/register`, {
        email,
        password,
        password_confirmation,
      })
      .then((response) => {
        toast(
          'Успешно зарегистрировались. На ваш email было отправлено письмо для подтверждения',
          'success'
        );
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  errorHandler(data) {
    if (data.request.status == 401) this.logoutSuccess();
    if (data.response?.data) toast(data.response.data.message, 'error');
    else toast('Неизвестная ошибка', 'error');
  }
}

const store = new AuthenticationStore();

export default store;

import React, { useState } from 'react';
import { createPortal } from 'react-dom';

import {
  BsGlobe2,
  BsGlobeEuropeAfrica,
  BsCashCoin,
  BsFillPiggyBankFill,
} from 'react-icons/bs';

import { Radio, RadioGroup, FormControlLabel, Checkbox } from '@mui/material';
import { categoryIcons } from '../../helpers/caterogy';

import styles from './styles.module.scss';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';

const categoryData = [
  'Бизнес',
  'Развлечение',
  'Спорт',
  'Здоровье',
  'Образование',
  'Социальное',
  'Прочее',
];

const Filters = (props) => {
  const { filters, isMap, onCancel, onAccept } = props;

  const [category, setCategory] = useState(
    filters.category || [0, 1, 2, 3, 4, 5, 6, 7]
  );
  const [format, setFormat] = useState(filters.format || 'all');
  const [paid, setPaid] = useState(filters.paid || 'all');

  const onCategoryChecked = (event, cat_id) => {
    const checked = event.target.checked;
    let newCategory = [];

    if (cat_id == 0) {
      if (checked) {
        newCategory = [0, 1, 2, 3, 4, 5, 6, 7];
      } else {
        newCategory = [];
      }
    } else {
      if (checked) {
        newCategory = [...category, cat_id];
        if (newCategory.length == 8) newCategory.push(0);
      } else {
        newCategory = category.filter((id) => id !== cat_id && id !== 0);
      }
    }

    setCategory(newCategory);

    console.log(newCategory);
  };

  const acceptFilters = () => {
    const _filters = {};
    if (format != 'all') _filters.format = format;
    if (paid != 'all') _filters.paid = paid;
    if (category.length != 8) _filters.category = category;

    onAccept(_filters);
  };

  return createPortal(
    <div className={styles.filters}>
      <div className={styles.header}>
        <div></div>
        <div className={styles.title}>Фильтры</div>
        <div className={styles.cancel} onClick={onCancel}>
          Отмена
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.filter}>
          <div className={styles.title}>Категория мероприятия </div>
          <div className={styles.btns}>
            <FormControlLabel
              checked={category.includes(0)}
              onChange={(event) => onCategoryChecked(event, 0)}
              control={<Checkbox />}
              label="Все"
            />
            {categoryData.map((label, index) => (
              <FormControlLabel
                key={`${index}-${label}`}
                checked={category.includes(index + 1)}
                onChange={(event) => onCategoryChecked(event, index + 1)}
                control={
                  <Checkbox
                    icon={categoryIcons[index]}
                    checkedIcon={categoryIcons[index]}
                  />
                }
                label={label}
              />
            ))}
          </div>
        </div>
        <div className={styles.filter}>
          <div className={styles.title}>
            Формат мероприятия{' '}
            {isMap && (
              <div className={styles.hint}>
                (в режиме карты данный фильтр недоступен)
              </div>
            )}
          </div>
          <div className={styles.btns}>
            <RadioGroup
              row
              value={format}
              onChange={(e) => setFormat(e.target.value)}
            >
              <FormControlLabel
                value="all"
                control={<Radio />}
                disabled={isMap}
                label="Все"
              />
              <FormControlLabel
                disabled={isMap}
                value="real"
                control={
                  <Radio
                    icon={<BsGlobeEuropeAfrica />}
                    checkedIcon={<BsGlobeEuropeAfrica />}
                  />
                }
                label="Реальные"
              />
              <FormControlLabel
                disabled={isMap}
                value="virtual"
                control={
                  <Radio icon={<BsGlobe2 />} checkedIcon={<BsGlobe2 />} />
                }
                label="Виртуальные"
              />
            </RadioGroup>
          </div>
        </div>
        <div className={styles.filter}>
          <div className={styles.title}>Стоимость</div>
          <div className={styles.btns}>
            <RadioGroup
              row
              value={paid}
              onChange={(e) => setPaid(e.target.value)}
            >
              <FormControlLabel value="all" control={<Radio />} label="Все" />
              <FormControlLabel
                value="true"
                control={
                  <Radio icon={<BsCashCoin />} checkedIcon={<BsCashCoin />} />
                }
                label="Платные"
              />
              <FormControlLabel
                value="false"
                control={
                  <Radio
                    icon={<BsFillPiggyBankFill />}
                    checkedIcon={<BsFillPiggyBankFill />}
                  />
                }
                label="Бесплатные"
              />
            </RadioGroup>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <SimpleButton onClick={acceptFilters} text="Применить" />
      </div>
    </div>,
    document.querySelector('#portal')
  );
};

export default Filters;

import {
  BsFillLockFill,
  BsFillUnlockFill,
  BsCoin,
  BsFillTrashFill,
} from 'react-icons/bs';
import { categoryIcons } from '../../../helpers/caterogy';
import styles from './styles.module.scss';
import moment from 'moment';

const EventCard = (props) => {
  const { owner, event, onDelete, onClick } = props;

  const startTime = moment(event.start_at).valueOf();
  const endTime = moment(event.end_at).valueOf();
  const nowTime = moment().valueOf();

  let timeLabel = '';
  let timeStyle = '';

  if (startTime < nowTime && nowTime < endTime) {
    timeLabel = 'сейчас идёт';
    timeStyle = styles.inTime;
  } else if (nowTime > endTime) {
    timeLabel = 'завершено';
    timeStyle = styles.outOfTime;
  }

  return (
    <div className={styles.event} onClick={() => onClick(event.id)}>
      <div className={styles.header}>
        <div className={styles.privacy}>
          {event.privacy_type === 'PU' ? (
            <BsFillUnlockFill title="публичное" />
          ) : (
            <BsFillLockFill title="приватное" />
          )}
        </div>
        {event.cost > 0 && (
          <div className={styles.cost} title={`участие ${event.cost} рублей`}>
            <BsCoin />
            <BsCoin />
            <BsCoin />
          </div>
        )}
        <div className={styles.category}>
          {event.categories.map((cat) => (
            <span key={cat.id}>{categoryIcons[cat.type_id - 1]}</span>
          ))}
        </div>
        {owner && (
          <div className={styles.controls}>
            <BsFillTrashFill
              title="удалить"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(event.id);
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.name}>{event.name}</div>
      <div className={`${styles.startTime} ${timeStyle}`}>
        <div className={styles.timeLabel}>{timeLabel}</div>
        <span>{moment(event.start_at).format('MMM Do YY')}</span>
      </div>
    </div>
  );
};

export default EventCard;

import React, { useEffect, useMemo, useState } from 'react';

import {
  BsInfinity,
  BsCircleFill,
  BsFillLockFill,
  BsFillUnlockFill,
  BsChevronBarExpand,
  BsChevronBarContract,
} from 'react-icons/bs';
import moment from 'moment';

import { Autocomplete, TextField, TablePagination } from '@mui/material';
import { getAllCities, getEvents, getEventsByCity } from '../../helpers/fetch';

import styles from './styles.module.scss';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import Loader from '../../ui/components/loader';
import { categoryIcons } from '../../helpers/caterogy';

const Event = (props) => {
  const { event, onClick, onEventOpen, selected } = props;

  const startTime = moment(event.start_at).valueOf();
  const endTime = moment(event.end_at).valueOf();
  const nowTime = moment().valueOf();

  let timeLabel = '';
  let timeStyle = '';

  if (startTime < nowTime && nowTime < endTime) {
    timeLabel = 'сейчас идёт';
    timeStyle = styles.inTime;
  } else if (nowTime > endTime) {
    timeLabel = 'завершено';
    timeStyle = styles.outOfTime;
  }

  return (
    <div className={`${styles.event} ${selected ? styles.selected : ''}`}>
      <div className={styles.shortinfo} onClick={onClick}>
        <div className={styles.title}>{event.name}</div>
        <div className={styles.shortDetais}>
          <div className={`${styles.time} ${timeStyle}`}>
            <div className={styles.start}>
              {moment(event.start_at).format('MMM Do YY')}
            </div>
            <div className={styles.end}>
              {moment(event.end_at).format('MMM Do YY')}
            </div>
          </div>
        </div>
        <div className={styles.arrow}>
          {selected ? (
            <BsChevronBarContract size={20} />
          ) : (
            <BsChevronBarExpand size={20} />
          )}
        </div>
      </div>
      <div className={styles.detailedInfo}>
        <div className={styles.details}>
          <div
            className={styles.cover}
            style={{ backgroundImage: `url(${event.cover?.file.url}` }}
          >
            <div className={styles.props}>
              {timeLabel && (
                <div className={styles.prop}>
                  {timeLabel} <BsCircleFill size={14} className={timeStyle} />
                </div>
              )}
              <div className={styles.prop}>
                {event.categories.map((cat) => (
                  <span key={cat.id}>{categoryIcons[cat.type_id - 1]}</span>
                ))}
              </div>
              <div className={styles.prop}>
                {event.privacy_type == 'PU' ? (
                  <>
                    <span>публичное</span>
                    <BsFillUnlockFill />
                  </>
                ) : (
                  <>
                    <span>приватное</span>
                    <BsFillLockFill />
                  </>
                )}
              </div>
              <div className={styles.prop}>
                <span>возраст</span>
                {event.participant_max_age == null ? (
                  <BsInfinity />
                ) : (
                  `${event.participant_min_age} - ${event.participant_max_age}`
                )}
              </div>
              <div className={styles.prop}>
                <span>количество</span>
                {event.participant_max_count == null ? (
                  <BsInfinity />
                ) : (
                  `${event.participant_min_count} - ${event.participant_max_count}`
                )}
              </div>
            </div>
          </div>
          <div className={styles.info}>
            <div>{event.description}</div>
          </div>
        </div>
        <SimpleButton text="открыть" onClick={() => onEventOpen(event.id)} />
      </div>
    </div>
  );
};

const ListEvents = (props) => {
  const { onEventOpen, filters } = props;
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [totalEvents, setTotalEvents] = useState(0);
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [citiesLoading, setCitiesLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [eventsPerPage, setEventsPerPage] = useState(25);

  const totalPages = useMemo(
    () => Math.ceil(totalEvents / eventsPerPage),
    [totalEvents]
  );

  async function _getEvents() {
    setLoading(true);
    const result =
      city == null
        ? await getEvents(eventsPerPage, eventsPerPage * page, filters, [
            'categories',
            'cover.file',
          ])
        : await getEventsByCity(
            city,
            eventsPerPage,
            eventsPerPage * page,
            filters,
            ['categories', 'cover.file']
          );

    if (result) {
      setTotalEvents(result.total_count);
      setEvents(result.list);
    }

    setLoading(false);
  }

  useEffect(() => {
    _getEvents();
  }, [city, page, filters]);

  const fetchCities = async () => {
    setCitiesLoading(true);

    const result = await getAllCities();
    setCities(
      result.map((c) => {
        return {
          id: c.id,
          label: c.name,
          city_code: c.kladr_id,
        };
      })
    );
    setCitiesLoading(false);
  };

  const handleChangeEventsPerPage = (event) => {
    setEventsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={styles.listEvents}>
      {loading && <Loader />}
      <div className={styles.city}>
        <Autocomplete
          disablePortal
          onChange={(event, options) => {
            setCity(options?.id || null);
          }}
          options={cities}
          fullWidth
          loading={citiesLoading}
          loadingText={'Загрузка городов'}
          noOptionsText={'Нет совпадений'}
          openText={'Показать список городов'}
          getOptionKey={(option) => option.id}
          onFocus={() => {
            if (cities.length == 0 && !citiesLoading) fetchCities();
          }}
          renderInput={(params) => <TextField {...params} label="Город" />}
        />
      </div>
      <div className={styles.events}>
        {events.map((event) => (
          <Event
            key={event.id}
            event={event}
            onClick={() =>
              setSelectedEvent(selectedEvent?.id == event.id ? null : event)
            }
            selected={selectedEvent?.id == event.id}
            onEventOpen={onEventOpen}
          />
        ))}
      </div>
      <div className={styles.pagination}>
        <TablePagination
          component="div"
          labelRowsPerPage={'кол-во'}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} из ${
              count !== -1 ? count : `больше чем ${to}`
            }`;
          }}
          disabled={totalPages == 1}
          count={totalPages}
          color={'primary'}
          page={page}
          rowsPerPage={eventsPerPage}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeEventsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </div>
    </div>
  );
};

export default ListEvents;

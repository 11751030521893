import { IoFootball } from 'react-icons/io5';
import {
  BsFillSuitcaseLgFill,
  BsBalloonFill,
  BsHeartPulseFill,
  BsMortarboardFill,
  BsPersonArmsUp,
  BsXDiamondFill,
} from 'react-icons/bs';

const business = <BsFillSuitcaseLgFill title="Бизнес" size={16} />;
const entertainment = <BsBalloonFill title="Развлечение" size={16} />;
const sport = <IoFootball title="Спорт" size={18} />;
const health = <BsHeartPulseFill title="Здоровье" size={16} />;
const education = <BsMortarboardFill title="Образование" size={16} />;
const societe = <BsPersonArmsUp title="Социальное" size={16} />;
const other = <BsXDiamondFill title="Прочее" size={16} />;

export const categoryIcons = [
  business,
  entertainment,
  sport,
  health,
  education,
  societe,
  other,
];

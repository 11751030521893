import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from '../../store/index';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField, Checkbox, Link } from '@mui/material/';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';

import styles from './styles.module.scss';
import Loader from '../../ui/components/loader';
import { toast } from '../../helpers/utils';

const Auth = observer(() => {
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [licenceConfirmed, setLicenceConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const authStore = useAuthStore();

  const onEmailInput = (e) => {
    const newValue = e.target.value;
    setEmail(newValue);
  };

  const onPassInput = (e) => {
    const newValue = e.target.value;
    setPass(newValue);
  };

  const onConfirmPassInput = (e) => {
    const newValue = e.target.value;
    setConfirmPass(newValue);
  };

  const onLicenceSwitched = (e, value) => {
    setLicenceConfirmed(value);
  };

  const onRegisterClick = async () => {
    if (pass.length < 8) {
      toast(
        'Количество символов пароля должно быть больше или равно 8',
        'warn'
      );
      return;
    }
    if (pass !== confirmPass) {
      toast('Пароль не совпадает', 'error');
      return;
    }
    if (email.length == 0) {
      toast('email не должен быть пустым', 'warn');
      return;
    }

    setLoading(true);
    const success = await authStore.register(email, pass, confirmPass);
    setLoading(false);
    if (success) navigate('/login');
  };

  const onLoginClick = async () => {
    if (pass.length < 8) {
      toast(
        'Количество символов пароля должно быть больше или равно 8',
        'warn'
      );
      return;
    }
    if (email.length == 0) {
      toast('email не должен быть пустым', 'warn');
      return;
    }

    setLoading(true);
    const success = await authStore.login(email, pass);
    setLoading(false);
  };

  if (pathname === '/register') {
    return (
      <div className={styles.register}>
        {loading && <Loader />}
        <div className={styles.title}>
          <span className={styles.logo} />
          <span className={styles.text}>Регистрация</span>
        </div>
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          required
          value={email}
          onInput={onEmailInput}
        />
        <TextField
          label="Пароль"
          variant="outlined"
          type="password"
          required
          value={pass}
          onInput={onPassInput}
        />
        <TextField
          label="Подтверждение"
          variant="outlined"
          type="password"
          required
          value={confirmPass}
          onInput={onConfirmPassInput}
        />
        <div className={styles.row}>
          <Checkbox value={licenceConfirmed} onChange={onLicenceSwitched} />
          <Link href="./agreement.docx" target="_blank">
            пользовательское соглашение
          </Link>
        </div>
        <SimpleButton
          onClick={onRegisterClick}
          text="Зарегистрироваться"
          disabled={!licenceConfirmed}
        />
      </div>
    );
  } else if (pathname === '/login') {
    return (
      <div className={styles.login}>
        {loading && <Loader />}
        <div className={styles.title}>
          <span className={styles.logo} />
          <span className={styles.text}>Авторизация</span>
        </div>
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          required
          value={email}
          onInput={onEmailInput}
        />
        <TextField
          label="Пароль"
          variant="outlined"
          type="password"
          required
          value={pass}
          onInput={onPassInput}
        />
        {/* <Link href="/forgot">забыли пароль?</Link> */}
        <Link href="/register">зарегистрироваться</Link>
        <SimpleButton onClick={onLoginClick} text="Войти" />
      </div>
    );
  } else
    return (
      <div className={styles.login}>
        <div className={styles.title}>
          <span className={styles.logo} />
          <span className={styles.text}>Сброс пароля</span>
        </div>
        <p>введите email указанный при регистрации</p>
        <p>на него прийдёт письмо с инструкцией по сбросу пароля</p>
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          required
          value={email}
          onInput={onEmailInput}
        />
        <Link href="/login">авторизоваться</Link>
        <Link href="/register">зарегистрироваться</Link>
        <SimpleButton onClick={() => {}} text="Сбросить пароль" />
      </div>
    );
});

export default Auth;
